<template>
    <div class="shortcuts-wrapper">
        <div class="shortcut-item" v-for="shortcut in filtered_shortcuts" @mouseleave="emit('keyDepressed',shortcut.keys)"
        :key="shortcut.name"
            @mouseenter="emit('keyPressed',shortcut.keys)">
            <div class="shortcut-name">{{ shortcut.name }}</div>
            <div class="shortcut-description">{{ shortcut.description }}</div>
        </div>
    </div>
</template>

<script setup>

import { computed, onMounted, ref, watchEffect } from 'vue';
import { useRoute } from 'vue-router';

const route = useRoute();

const emit = defineEmits(['keyPressed','keyDepressed']);
const filtered_shortcuts = ref();
// const filtered_shortcuts = computed(() => {
//     console.log( route.name )
//     if (route && route.name) {
//         let rn = route.name;
//         return shortcuts.filter(s => {
//             return s.pages.includes( rn.toLowerCase());
//         })
//     } else {
//         return shortcuts;
//     }
// })


const shortcuts = [
    {
        name: "1,2,3",
        description: "Toggle Momentum,Depth,Power",
        keys: ["49", "50", "51"],
        pages: ["browse","edit"]
    },
    {
        name: "K",
        description: "Stop",
        keys: ["k"],
        pages: ["browse","edit"]
    },
    {
        name: "L",
        description: "Play",
        keys: ["l"],
        pages: ["browse","edit"]
    },
    {
        name: "Spacebar",
        description: "Play/Pause",
        keys: [" "],
        pages: ["browse","edit"]
    },
    {
        name: "Enter",
        description: "Pause/Return to start",
        keys: ["13"],
        pages: ["browse","edit"]
    },
    {
        name: "CMD/CTRL + Z",
        description: "Undo",
        keys: ["91", "z"],
        pages: ["browse","edit"]
    },
    {
        name: "CMD/CTRL + SHIFT + Z",
        description: "Redo",
        keys: ["91","16", "z"],
        pages: ["browse","edit"]
    },
    {
        name: "CMD/CTRL + C",
        description: "Copy",
        keys: ["91", "c"],
        pages: ["browse","edit"]
    },
    {
        name: "CMD/CTRL + V",
        description: "Paste",
        keys: ["91", "v"],
        pages: ["browse","edit"]
    },
    {
        name: "CMD/CTRL + S",
        description: "Save",
        keys: ["91", "S"],
        pages: ["browse","edit"]
    },
    {
        name: "CMD/CTRL + '",
        description: "Add Keyframe",
        keys: ["91",'222'],
        pages: ["browse","edit"]
    },
    {
        name: "CMD/CTRL + A",
        description: "Select All",
        keys: ["91",'A'],
        pages: ["browse","edit"]
    },
    {
        name: "CMD/CTRL + D",
        description: "Clear Selection",
        keys: ["91",'D'],
        pages: ["browse","edit"]
    },
    {
        name: "Left Arrow",
        description: "Move Playhead one frame left",
        keys: ["37"],
        pages: ["browse","edit"]
    },
    {
        name: "Right Arrow",
        description: "Move Playhead one frame right",
        keys: ["39"],
        pages: ["browse","edit"]
    },
    {
        name: "SHIFT + L Arrow",
        description: "Move Playhead 1 second left",
        keys: ["37","16"],
        pages: ["browse","edit"]
    },
    {
        name: "SHIFT + R Arrow",
        description: "Move Playhead 1 second right",
        keys: ["39","16"],
        pages: ["browse","edit"]
    },

    // {
    //     name: "Up/Down",
    //     description: "Previous / Next Song",
    //     keys: ["38"],
    //     pages: ["browse"]
    // }
]


watchEffect(()=>{
    if (route && route.name) {
        let rn = route.name;
        filtered_shortcuts.value = shortcuts.filter(s => {
            return s.pages.includes( rn.toLowerCase());
        })
    } else {
        filtered_shortcuts.value = shortcuts;
    }
})

onMounted(()=>{
    shortcuts.forEach(sho=>{
        console.log( sho )
        sho.keys.forEach(char=>{
            var key = document.querySelector('[data-char="' + char.toUpperCase() + '"]');
            if (!key) {
                key = document.querySelector('[data-key="' + char.toUpperCase() + '"]');
            }
            if( key ){
                key.setAttribute('data-has-shortcut',true);
            }
        })
    })
})


</script>

<style lang="scss" scoped>


.shortcut-item {
    display: flex;
    border-bottom: solid 1px;
    margin-bottom: 5px;
    cursor: pointer;
    font-size: 10px;
    padding: 2px 5px;
    padding-bottom: 3px;
    // display: grid;
    // grid-template-columns: 1.1fr 2fr;
    &:hover{
        background: var(--filmstro-black);
    }
}

.shortcut-description {
    margin-left: auto;
    color: #979797;
}

.keyboard-wrapper {
    // position: fixed;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99999999;
}
.shortcut-name{
    text-align: left;
}

</style>